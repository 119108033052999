import React, { useRef } from "react";
import { Input } from "reactstrap";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Swal from "sweetalert2";

const DownloadPDF = ({ children, fileName, type }) => {

    const newFileName = fileName;
    const componentRef = useRef();  // สร้าง reference สำหรับเก็บการอ้างอิงถึง DOM ของส่วนที่ต้องการแปลงเป็น PDF

    const handleDownload = async () => {
        Swal.fire({
            icon: 'info',
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });
  
        const input = componentRef.current;

        if(type === "formInput"){
            // ซ่อนส่วนที่ไม่ต้องการ
            const hideInput = document.querySelector('#section-page-hide-input');
            if (hideInput) {
                hideInput.style.visibility = "hidden";
            }
        
            // จัดการเพิ่มขนาดเฉพาะ section-page-1-1
            const sectionPage1_1 = document.querySelector("#section-page-1-1");  // เลือกส่วนที่ต้องการ
        
            // if (sectionPage1_1) {
            //     sectionPage1_1.style.fontSize = 'larger'; // เพิ่มขนาดตัวอักษรให้ใหญ่ขึ้น
            // }

            const allElements = sectionPage1_1.querySelectorAll("label, input, span"); 
            allElements.forEach((element) => {
                const computedStyle = window.getComputedStyle(element); // ดึงค่าปัจจุบัน
                const fontSize = computedStyle.fontSize; // ค่าของ font-size
                const sizeValue = parseFloat(fontSize); // แปลงค่าเป็นตัวเลข
                const unit = fontSize.replace(sizeValue, ""); // ดึงหน่วย (px, em, ฯลฯ)

                if (!isNaN(sizeValue)) {
                    element.style.fontSize = (sizeValue + 3.8) + unit; // เพิ่มขนาดอีก 10px

                    // ปรับ line-height ให้สัมพันธ์กับฟอนต์
                    element.style.lineHeight = (sizeValue + 3.8) + unit;

                    // หากเป็น input ให้ปรับ padding ด้วย
                    if (element.tagName.toLowerCase() === "input") {
                        const currentPadding = parseFloat(computedStyle.paddingTop);
                        element.style.paddingTop = (currentPadding + 3) + unit;  // เพิ่ม padding ให้พอดี
                        element.style.paddingBottom = (currentPadding + 2) + unit;
                    }
                }
            });

            // ฟังก์ชันคืนค่าหลังจากทำเสร็จ
            const restoreHideInput = () => {
                if (hideInput) {
                    hideInput.style.visibility = "visible";
                }
            };

            // ฟังก์ชันสำหรับคืนค่า font-size กลับเป็นค่าเดิม
            const resetFontSize = () => {
                const sectionPage1_1 = document.querySelector("#section-page-1-1"); // เลือกส่วนที่ต้องการ
                if (sectionPage1_1) {
                    const allElements = sectionPage1_1.querySelectorAll("label, input, span");
                    allElements.forEach((element) => {
                        element.style.fontSize = ""; // คืนค่า font-size กลับเป็นค่าเริ่มต้น
                        element.style.lineHeight = ""; // คืนค่า line-height กลับเป็นค่าเริ่มต้น

                        // หากเป็น input ให้คืนค่า padding กลับเป็นค่าเดิม
                        if (element.tagName.toLowerCase() === "input") {
                            element.style.paddingTop = "";  // คืนค่า padding-top กลับเป็นค่าเริ่มต้น
                            element.style.paddingBottom = "";  // คืนค่า padding-bottom กลับเป็นค่าเริ่มต้น
                        }
                    });
                }
            };
        
            const padding = [0.5, 0.5, 0.5, 0.3];
            const imgWidth = 8.5;
            const pageHeight = 11;
            const innerPageWidth = imgWidth - padding[0] * 2;
            const innerPageHeight = pageHeight - padding[2] - padding[3];
        
            const pdf = new jsPDF("p", "in", [imgWidth, pageHeight]);
        
            const sections = input.querySelectorAll("[id^='section-page-']:not(#section-page-1-1 #section-page-hide-input)");
            let currentY = padding[1];
            let currentPage = "1";
            let currentSubPage = null;
        
            for (const section of sections) {
                const sectionId = section.id;
                const cols = Array.from(section.querySelectorAll('[class*="col-"]'));
                const originalClasses = cols.map(col => Array.from(col.classList));
            
                cols.forEach((col, index) => {
                    const classes = originalClasses[index];
                    const newClasses = classes.map(className => {
                        if (className.match(/col-(?:xs-|sm-|md-|lg-|xl-)?10/)) {
                            return className.replace('10', '12');
                        }
                        if (className.match(/col-(?:xs-|sm-|md-|lg-|xl-)?5/)) {
                            return className.replace('5', '6');
                        }
                        if (className.match(/col-(?:xs-|sm-|md-|lg-|xl-)?8/)) {
                            return className.replace('8', '10');
                        }
                        return className;
                    });
            
                    classes.forEach(className => col.classList.remove(className));
                    newClasses.forEach(className => col.classList.add(className));
                });
        
                const [prefix, pageNumber, subPageNumber] = sectionId.split('-').slice(-3);
                const sectionPage = pageNumber;
                const sectionSubPage = subPageNumber;
        
                try {
                    const sectionCanvas = await html2canvas(section, {
                        scale: 2,
                        useCORS: true,
                        allowTaint: true,
                        scrollY: -window.scrollY,
                        logging: false,
                        letterRendering: 1,
                    });
            
                    const sectionImgData = sectionCanvas.toDataURL("image/jpeg", 0.98);
                    const sectionImgHeight = sectionCanvas.height * innerPageWidth / sectionCanvas.width;
            
                    if (sectionPage !== currentPage) {
                    pdf.addPage();
                    currentY = padding[1];
                    currentPage = sectionPage;
                    currentSubPage = null;
                    }
            
                    if (sectionSubPage !== currentSubPage) {
                    currentSubPage = sectionSubPage;
                    }
            
                    if (sectionPage !== currentPage || currentY + sectionImgHeight > innerPageHeight && sectionSubPage === currentSubPage) {
                    pdf.addPage();
                    currentY = padding[1];
                    }
            
                    pdf.addImage(sectionImgData, "JPEG", padding[0], currentY, innerPageWidth, sectionImgHeight);
                    currentY += sectionImgHeight;
                } catch (error) {
                    console.error("Error occurred while generating the PDF: ", error);
                    restoreHideInput();
                    resetFontSize();
                    Swal.close();
                    return;
                }
            
                cols.forEach((col, index) => {
                    const classes = Array.from(col.classList);
                    classes.forEach(className => col.classList.remove(className));
                    originalClasses[index].forEach(className => col.classList.add(className));
                });
            }
        
            // คืนค่าขนาดเดิมให้กับ section-page-1-1 หลังจากแปลงเป็น PDF
            if (sectionPage1_1) {
                sectionPage1_1.style.fontSize = "";
            }
        
            restoreHideInput();
            resetFontSize();
            Swal.close();
            pdf.save(newFileName || "downloadedForm.pdf");
        } else if(type === "formLabel"){

            const hideInput = document.querySelector('#section-page-hide-input');
            if (hideInput) {
                hideInput.style.visibility = "hidden";
            }

            // ฟังก์ชันคืนค่าหลังจากทำเสร็จ
            const restoreHideInput = () => {
                if (hideInput) {
                    hideInput.style.visibility = "visible";
                }
            };

            // เลือกทุกองค์ประกอบที่มี id ขึ้นต้นด้วย 'section-page-' แต่ไม่รวม id ที่ต้องการยกเว้น
            const sectionElements = input.querySelectorAll("[id^='section-page-']:not(#section-page-hide-input)");

            // แปลง NodeList ที่ได้มาเป็น Array และเก็บเฉพาะ id ของแต่ละ element
            const sectionIds = Array.from(sectionElements).map(element => element.id);

            const pdf = new jsPDF('p', 'in', [8.5, 11]);
            const image = { type: 'jpeg', quality: 0.98 };
            const margin = [0.1, 0.3, 0.4]; // Left, top, and bottom margins.
            const imgWidth = 8.5;
            const innerPageWidth = imgWidth - margin[0] * 2;
            
            for (let i = 0; i < sectionIds.length; i++) {
                const sectionElement = document.getElementById(sectionIds[i]);
                
                if (sectionElement) {
                    const canvas = await html2canvas(sectionElement, { scale: 2, useCORS: true, allowTaint: true, scrollY: 0 });
                    const imgData = canvas.toDataURL('image/' + image.type, image.quality);
                    const pageHeight = (canvas.height * innerPageWidth) / canvas.width;
                    
                    if (i > 0) {
                        pdf.addPage();
                    }
                    
                    pdf.addImage(imgData, image.type, margin[0], margin[1], innerPageWidth, pageHeight);
                }
            }
            Swal.close();
            restoreHideInput();
            pdf.save(newFileName || "downloadedForm.pdf");
        }
    };
  
    return (
        <div>
            {/* อ้างอิงไปยังส่วนที่ต้องการแปลงเป็น PDF */}
            <div ref={componentRef}>
                {children}
            </div>
            <Input
                id="export-pdf"
                type="button"
                value="Download PDF"
                className="form-export-file-input"
                onClick={handleDownload}
            />
        </div>
    );
};

export default DownloadPDF;